import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "description" ]

  showDescription() {
    this.descriptionTarget.style.visibility = "visible";
  }

  hideDescription() {
    this.descriptionTarget.style.visibility = "hidden";
  }
}