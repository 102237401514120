import { Controller } from "stimulus"

export default class extends Controller {
  close() {
    document.getElementById("job-detail").style.display = "none";
    document.getElementById("job-detail-mobile").classList.remove("job-detail-mobile-display");
  }

  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const positionId = urlParams.get('position_id');

    if (positionId != undefined) {
      document.getElementById("job-detail-mobile").classList.add("job-detail-mobile-display");
      document.getElementById("job-detail").style.display = "block";
    }

    if (window.innerWidth < 1201) {
      var child1 = document.getElementById("job-detail-mobile");
      var parent = document.getElementById("position-content");

      if (child1.offsetHeight > 0) {
        var childHeight = parseInt(child1.offsetHeight + 300) + "px";
        parent.style.height = childHeight;
      }
    }
  }
}