import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "slide" ]
  static values = { index: Number }

  next() {
    this.indexValue++
  }

  previous() {
    this.indexValue--
  }

  indexValueChanged() {
    if (this.slideTargets.length == this.indexValue) {
      this.indexValue = 0
    }

    if (0 > this.indexValue) {
      this.indexValue = (this.slideTargets.length - 1)
    }
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      // element.hidden = (index != this.indexValue)
      if (index != this.indexValue) {
        element.style.display = 'none';
      } else {
        element.style.display = 'flex';
      }
    })
  }
}