import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "answer", "question" ]

  toggleAnswer() {
    if (this.answerTarget.style.display == 'none' || this.answerTarget.style.display == '') {
      document.querySelectorAll(".answer").forEach((element, index) => {
        element.style.display = 'none';
      })

      document.querySelectorAll(".question").forEach((element, index) => {
        if (element.classList.contains("selected")) {
          element.classList.toggle("selected");
        }
      })

      this.questionTarget.classList.toggle("selected");
      this.answerTarget.style.display = 'block';
    } else {
      this.answerTarget.style.display = 'none';
    }
  }
}